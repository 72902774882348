import { TRIGGER_NOTIFICATION } from "./types";

export const triggerGenericNotification = (dispatch, error) => {
  let errorString = "";
  if (error && error.response) {
    if (error.response.status === 400) {
      errorString = " You might be providing some invalid information and therefore are seeing an error. Make sure your information is provided in the correct format expected."
    }
    else if (error.response.status === 401) {
      errorString = " You might be providing some invalid information and therefore are seeing an error. Make sure your information is provided in the correct format expected."
    }
    else if (error.response.status === 403) {
      errorString = " This operation is not being allowed either due to information provided that is not as expected."
    }
    else if (error.response.status === "GoogleOAuthError") {
      errorString = " We have not been able to receive all information from Google we need for sign in. Please try Signup using email/password."
    }
  }
  dispatch({
    type: TRIGGER_NOTIFICATION,
    payload: {
      message:
        "Oops, something went wrong." + errorString,
      error: true,
    },
  });
  throw error;
};
